import { Subject } from 'rxjs';
import { bindAndSubscribe } from 'utils/rx';
import { startWith } from 'rxjs/operators';

const SAVED_THEME_KEY = '@@QNOTE/theme';

type ThemeType = 'dark' | 'light';

const activeThemeSubject = new Subject<ThemeType>();

export const [useActiveTheme, activeTheme$] = bindAndSubscribe(
  activeThemeSubject.pipe(
    startWith((localStorage.getItem(SAVED_THEME_KEY) as ThemeType) || 'light')
  )
);

export const setActiveTheme = (theme: ThemeType) => {
  activeThemeSubject.next(theme);
};

// Keep the theme in sync
activeThemeSubject.subscribe((theme) => {
  localStorage.setItem(SAVED_THEME_KEY, theme);
});
