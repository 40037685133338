import { BehaviorSubject } from 'rxjs';
import { bindAndSubscribe } from 'utils/rx';
import { logger } from 'lib/logger';
import { useDocument } from './documents';

// Active Document Slug
const activeDocument$ = new BehaviorSubject<string | null>(null);

export const setActiveDocument = (slug: string) => activeDocument$.next(slug);
export const clearActiveDocument = () => activeDocument$.next(null);

export const [useActiveDocumentSlug] = bindAndSubscribe(activeDocument$);

export const useActiveDocument = () => {
  const slug = useActiveDocumentSlug();
  const doc = useDocument(slug || '');
  return doc || null;
};

// Navigator open state
type DocumentNavigatorState = {
  open: boolean;
};
const documentNavigatorState = new BehaviorSubject<DocumentNavigatorState>({
  open: true,
});

export const [useDocumentNavigatorState] = bindAndSubscribe(
  documentNavigatorState
);

export const setDocumentNavigatorOpen = (val: boolean) => {
  documentNavigatorState.next({
    ...documentNavigatorState.value,
    open: val,
  });
};

export const toggleDocumentNavigatorOpen = () => {
  documentNavigatorState.next({
    ...documentNavigatorState.value,
    open: !documentNavigatorState.value.open,
  });
};

// Effects
// TODO: figure out why this causes a crash when the cursor is on the document
// Update the UI to focus the newly created document
// documentCreated$.subscribe((doc) => {
//   clearActiveDocument();
//   setActiveDocument(doc.slug);
// });

// Logging

activeDocument$.subscribe((slug) => {
  logger.debug('Active document set: ', slug);
});
