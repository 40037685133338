const randomVal = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const alphabet =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // base 62

// Generates a "sufficiently random" slug
// total space is on the order of 10^14
export const generateSlug = () => {
  const slugLength = randomVal(5, 8);
  const alphabetLength = alphabet.length;
  let slug = '';
  for (let i = 0; i < slugLength; i++) {
    // select a random value from the alphabet
    const char = alphabet[randomVal(0, alphabetLength - 1)];
    slug = slug + char;
  }
  return slug;
};
