import React from 'react';
import styled from 'styled-components';
import { fade } from 'lib/theme';
import { IconButton } from 'components/IconButton';
import { newDocument, useDocuments } from 'state/documents';
import {
  setActiveDocument,
  setDocumentNavigatorOpen,
  toggleDocumentNavigatorOpen,
  useActiveDocumentSlug,
  useDocumentNavigatorState,
} from 'state/editor';
import { VscNewFile, VscMenu } from 'react-icons/vsc';
import { Block } from 'components/BlockEditor';

const EXPANDED_WIDTH = '275px';
const CONTRACTED_WIDTH = '50px';

const DocumentListContainer = styled.div<{ expanded: boolean }>`
  width: ${(props) => (props.expanded ? EXPANDED_WIDTH : CONTRACTED_WIDTH)};
  display: flex;
  min-width: ${(props) => (props.expanded ? EXPANDED_WIDTH : CONTRACTED_WIDTH)};
  background-color: ${(props) => props.theme.colors.background.light};
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => (props.expanded ? props.theme.spacing(1) : 0)};
  overflow-x: hidden;
  transition: all 0.2s ease-out;
`;

const DocumentListItem = styled.div<{ selected: boolean }>`
  min-height: 120px;
  max-height: 120px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${(props) => fade(props.theme.colors.text.light, 0.5)};
  padding: 0 ${(props) => props.theme.spacing(1)};
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border-right: ${(props) =>
    `4px solid ${
      props.selected ? props.theme.colors.text.light : 'transparent'
    };`};
`;

const FadeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 60%,
    ${(props) => props.theme.colors.background.light}
  );
`;

const DocumentTitle = styled.h1`
  font-size: 1.3rem;
`;

const DocumentPreview = styled.span`
  opacity: 0.8;
`;

const DocumentControls = styled.div<{ expanded: boolean }>`
  display: flex;
  justify-content: flex-end;
  transition: all 0.2s ease-out;
  flex-direction: ${(props) => (props.expanded ? 'row' : 'column-reverse')};
`;

type ListItemProps = {
  title: string;
  text: string;
  onClick: () => void;
  selected?: boolean;
};
const ListItem = ({ title, text, onClick, selected }: ListItemProps) => {
  return (
    <DocumentListItem onClick={onClick} selected={selected || false}>
      <DocumentTitle>{title}</DocumentTitle>
      <DocumentPreview>{text}</DocumentPreview>
      <FadeOverlay />
    </DocumentListItem>
  );
};

const parseDocumentPreview = (content: string) => {
  if (!content) {
    return '';
  }
  let preview = '';
  const parsedBlocks: Block[] = JSON.parse(content);
  let i = 1;
  while (preview.length < 80 && parsedBlocks[i]) {
    preview += parsedBlocks[i].value;
    i++;
  }
  return preview;
};

interface WindowDimensions {
  width: number;
  height: number;
}
const getDimensions = (): WindowDimensions => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = React.useState<WindowDimensions>(
    getDimensions()
  );
  React.useEffect(() => {
    const updateDimensions = () => setDimensions(getDimensions());

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return dimensions;
};

const useAutoMenuCloseOnScreenSize = () => {
  const dimensions = useWindowDimensions();
  React.useEffect(() => {
    if (dimensions.width < 640) {
      setDocumentNavigatorOpen(false);
    } else {
      setDocumentNavigatorOpen(true);
    }
  }, [dimensions.width]);
};

export const DocumentNavigator = () => {
  const { open } = useDocumentNavigatorState();
  useAutoMenuCloseOnScreenSize();
  const documentsMap = useDocuments();
  const activeSlug = useActiveDocumentSlug();
  const docsList = Object.values(documentsMap || {}).sort((a, b) => {
    return b.updatedAt - a.updatedAt;
  });

  const handleCreate = () => {
    newDocument('A new note');
  };

  return (
    <DocumentListContainer expanded={open}>
      <DocumentControls expanded={open}>
        <IconButton onClick={handleCreate}>
          <VscNewFile />
        </IconButton>
        <IconButton onClick={toggleDocumentNavigatorOpen}>
          <VscMenu />
        </IconButton>
      </DocumentControls>
      {open &&
        docsList.map((doc) => (
          <ListItem
            title={doc.title}
            selected={activeSlug === doc.slug}
            text={parseDocumentPreview(doc.content)}
            key={doc.slug}
            onClick={() => setActiveDocument(doc.slug)}
          />
        ))}
    </DocumentListContainer>
  );
};
