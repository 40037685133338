import React from 'react';
import { useBlockEditor, BlockEditor } from '../BlockEditor/BlockEditor';
import { Block, BlockType, RenderBlockFn } from '../BlockEditor/types';
import styled from 'styled-components';
import { GoListUnordered } from 'react-icons/go';
import { FaHeading } from 'react-icons/fa';
import { BiHeading } from 'react-icons/bi';
import { CgFormatHeading } from 'react-icons/cg';
import { BlockButton } from './BlockButton';
import { Toolbar } from './Toolbar';
import { CheckListItemBlock } from './CheckListItemBlock';
import { useActiveDocument, clearActiveDocument } from 'state/editor';
import { deleteDocument } from 'state/documents';
import { parseDocumentContent } from 'utils/documents';
import { EditorButton } from './EditorButton';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { logger } from 'lib/logger';
import { useDebounced } from './hooks';

const BulletListItem = styled.div`
  display: list-item;
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 50px;
`;

const renderBlock: RenderBlockFn = ({ block, inputProps }) => {
  switch (block.type) {
    case 'heading-1':
      return (
        <h1 style={{ minHeight: '1em' }} {...inputProps}>
          {block.value}
        </h1>
      );
    case 'paragraph':
      return <span {...inputProps}>{block.value}</span>;
    case 'bullet-list-item':
      return <BulletListItem {...inputProps}>{block.value}</BulletListItem>;
    case 'heading-2':
      return (
        <h2 style={{ minHeight: '1em' }} {...inputProps}>
          {block.value}
        </h2>
      );
    case 'heading-3':
      return (
        <h3 style={{ minHeight: '1em' }} {...inputProps}>
          {block.value}
        </h3>
      );
    case 'check-list-item':
      return <CheckListItemBlock block={block} inputProps={inputProps} />;
  }
};

// Blocks where the desired behavior of pressing enter while editing one of these blocks is
// to insert a new block of the same type below, instead of just a new default block
const MULTI_LINE_BLOCKS: BlockType[] = ['bullet-list-item', 'check-list-item'];

type Props = {
  handleSave: (value: Block[]) => void;
};

export const MyBlockEditor = ({ handleSave }: Props) => {
  const prevDocumentSlug = React.useRef<string | undefined>();
  const editor = useBlockEditor({
    hotKeys: {
      'cmd+h': 'heading-1',
    },
    multilineBlocks: MULTI_LINE_BLOCKS,
  });
  const activeDocument = useActiveDocument();
  React.useEffect(() => {
    if (
      activeDocument?.slug &&
      activeDocument?.slug !== prevDocumentSlug.current
    ) {
      prevDocumentSlug.current = activeDocument.slug;
      const blocks = parseDocumentContent(activeDocument.content);
      logger.debug('Setting document content', blocks);

      editor.resetEditorState(blocks);
    }
  }, [activeDocument?.slug, editor]);

  const debouncedSave = useDebounced(
    (value: Block[]) => {
      handleSave(value);
    },
    1000,
    [handleSave]
  );

  const handleChange = (state: Block[]) => {
    debouncedSave(state);
  };

  const handleDeleteDoc = () => {
    if (activeDocument) {
      const confirmed = window.confirm(
        'Are you sure you want to delete this document?'
      );
      if (confirmed) {
        clearActiveDocument();
        deleteDocument(activeDocument?.slug);
      }
    }
  };

  // TODO: subscribe to changes in activeDocument id and reset the editor state if it is different

  return (
    <div>
      <Toolbar>
        <BlockButton
          blockType="heading-1"
          icon={<FaHeading />}
          editor={editor}
        />
        <BlockButton
          blockType="heading-2"
          icon={<BiHeading />}
          editor={editor}
        />
        <BlockButton
          blockType="heading-3"
          icon={<CgFormatHeading />}
          editor={editor}
        />
        <BlockButton
          blockType="bullet-list-item"
          icon={<GoListUnordered />}
          editor={editor}
        />
        <EditorButton onClick={handleDeleteDoc}>
          <RiDeleteBin5Fill />
        </EditorButton>
      </Toolbar>
      <BlockEditor
        editor={editor}
        renderBlock={renderBlock}
        onChange={handleChange}
      />
    </div>
  );
};
