import { useRef, useEffect, useCallback } from 'react';

type AnyFn = (...params: any[]) => any;

export const useDebounced = (fn: AnyFn, debounceMs: number = 1000, deps: any[] = []) => {
  const debounceTimeout = useRef<any>();

  useEffect(() => {
    const cleanupTimeout = () => {
      if (debounceTimeout.current) {
        window.clearTimeout(debounceTimeout.current);
      }
    };
    // Clear any autosave timers on unmount
    return cleanupTimeout;
  }, []);

  const debouncedMethod = useCallback((...args: any[]) => {
      if (debounceTimeout.current) {
        window.clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = window.setTimeout(
        () => fn(...args),
        debounceMs || 1000
      );
  }, deps);

  return debouncedMethod;
}