import { BlockSlice } from 'components/BlockEditor';

export const parseDocumentContent = (content: string): BlockSlice[] => {
  if (content) {
    // FIXME: Individually parse and validate the blocks here instead of just
    // assuming they are well formed
    return JSON.parse(content);
  }
  return [
    {
      type: 'paragraph',
      value: ' ',
    },
  ];
};
