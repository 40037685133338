import styled from 'styled-components';

export const Input = styled.input`
  line-height: 1.5rem;
  font-size: 1.1rem;
  padding: 0.5rem;
  min-width: 320px;
  border-width: 0 0 1.5px 0;
  background-color: transparent;
  margin-bottom: 1rem;
  transition: 0.25s all ease-in-out;
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`;
