import { BehaviorSubject } from 'rxjs';
import { FirebaseUser } from 'lib/firebase';
import { AuthService } from 'lib/auth';
import { logger } from 'lib/logger';
import { bindAndSubscribe } from 'utils/rx';

type User = FirebaseUser;

export const currentUser$ = new BehaviorSubject<User | null>(
  AuthService.currentUser || null
);

export const setCurrentUser = (user: User | null) => {
  currentUser$.next(user);
};

export const initAuthState = () => {
  // Keep user state in sync with firebase
  AuthService.onAuthStateChanged((user) => {
    if (user) {
      logger.debug('Got user: ', user);
      setCurrentUser(user);
    } else {
      logger.debug('Logging out user.');
      setCurrentUser(null);
    }
  });
};

export const [useCurrentUser] = bindAndSubscribe(currentUser$);

currentUser$.subscribe((user) => {
  logger.debug('GOT USER: ', user?.uid);
});

// Auth modal
interface AuthModalState {
  open: boolean;
  view: 'signup' | 'login';
}
const authModalState$ = new BehaviorSubject<AuthModalState>({
  open: false,
  view: 'signup',
});

export const setAuthModalState = (state: Partial<AuthModalState>) => {
  authModalState$.next({
    ...authModalState$.value,
    ...state,
  });
};

export const [useAuthModalState, modal$] = bindAndSubscribe(authModalState$);

modal$.subscribe((val) => console.log('MODAL: ', val));
