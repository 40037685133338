import { db, FirebaseUser } from './firebase';
import { IDocument } from 'types/documents';
import { logger } from './logger';

export const saveDocToDB = (doc: IDocument, user: FirebaseUser) => {
  return db
    .collection('documents')
    .doc(doc.slug)
    .set({
      ...doc,
      owner: user.uid,
    })
    .then(() => {
      logger.debug('Document successfully saved to db!');
    })
    .catch((error) => {
      console.error('Error writing document: ', error);
    });
};

export const fetchDocsForUser = (user: FirebaseUser) => {
  return db.collection('documents').where('owner', '==', user.uid).get();
};

export const removeDocFromDB = (slug: string) => {
  return db
    .collection('documents')
    .doc(slug)
    .delete()
    .then(() => {
      logger.debug(`Removed ${slug} document from database`);
    })
    .catch((error) => {
      console.error('Error deleting document: ', error);
    });
};
