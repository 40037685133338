// import React from 'react';
import styled from 'styled-components';

type SpacingLevel = 0.25 | 0.5 | 0.75 | 1 | 1.5 | 2 | 3;

interface BoxProps {
  justify?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  row?: boolean;
  align?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
  flex?: number;
  p?: SpacingLevel | SpacingLevel[];
  m?: SpacingLevel | SpacingLevel[];
}

const spacingToCSS = (spacing: SpacingLevel | SpacingLevel[]): string => {
  if (Array.isArray(spacing)) {
    return spacing.map((s) => s + 'rem').join(' ');
  }
  return spacing + 'rem';
};

export const Box = styled.div<BoxProps>`
  display: flex;
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'initial'};
  flex: ${(props) => props.flex || 'initial'};
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  padding: ${(props) => (props.p ? spacingToCSS(props.p) : 'initial')};
  margin: ${(props) => (props.m ? spacingToCSS(props.m) : 'initial')};
`;

export const Paper = styled(Box)`
  background-color: ${(props) => props.theme.colors.background.main};
  padding: 1rem;
  border-radius: 10px;
`;
