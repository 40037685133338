import merge from 'lodash.merge';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

interface ColorSwatch {
  light: string;
  main: string;
  dark: string;
}

type SpacingLevel = 0.5 | 1 | 2 | 3 | 4 | 5 | 6;
type BorderRadiusLevel = 1 | 2 | 3;
export interface AppTheme {
  palette: {
    primary: ColorSwatch;
    gray: ColorSwatch;
    black: ColorSwatch;
    // secondary: ColorSwatch;
    // error: ColorSwatch;
    // warning: ColorSwatch;
    // info: ColorSwatch;
    // success: ColorSwatch;
  };
  colors: {
    background: ColorSwatch;
    text: ColorSwatch;
    contrastText: ColorSwatch;
  };
  spacing: (level: SpacingLevel) => string;
  borderRadius: (level: BorderRadiusLevel) => string;
  // fontSize;
}

const SPACING_STOP_SIZE = 10;
// const MAX_SPACING_STOPS = 6;
const BORDER_RADIUS_STOP_SIZE = 3;

export const defaultTheme: AppTheme = {
  palette: {
    primary: {
      main: '#4961E2',
      light: '#7187FF',
      dark: '#1F35A8',
    },
    gray: {
      light: '#e6e6e6',
      main: '#C0C0C0',
      dark: '#5E5E5E',
    },
    black: {
      light: '#1f1f1f',
      main: '#1f1f1f',
      dark: '#000000',
    },
  },
  colors: {
    background: {
      light: '#FFFFFF',
      main: '#f6f8ff',
      dark: '#b9bcca',
    },
    text: {
      main: '#000000',
      light: '#e6e6e6',
      dark: '#00000',
    },
    contrastText: {
      main: '#000000',
      light: '#e6e6e6',
      dark: '#00000',
    },
  },
  spacing: (level: SpacingLevel) => {
    return `${level * SPACING_STOP_SIZE}px`;
  },
  borderRadius: (level: BorderRadiusLevel) => {
    return `${level * BORDER_RADIUS_STOP_SIZE}px`;
  },
};

const makeTheme = (config: any): AppTheme => merge({}, defaultTheme, config);

export const darkTheme = makeTheme({
  palette: {
    primary: {
      light: '#5da8f9',
      main: '#4098f6',
      dark: '#3c6bf8',
    },
    gray: {
      light: '#3f475d',
      main: '#f5f5f5',
      dark: '#cfcfcf',
    },
  },
  colors: {
    background: {
      light: '#242c43',
      main: '#161b2f',
      dark: '#000000',
    },
    text: {
      main: '#f5f5f5',
      light: '#ffffff',
      dark: '#cfcfcf',
    },
    contrastText: {
      main: '#5E5E5E',
      light: '#e6e6e6',
      dark: '#000000',
    },
  },
});

/**
 *
 * @param opacity Must be a number between 0 and 1
 */
const hexOpacity = (opacity: number): string => {
  const hex = Math.round(opacity * 255)
    .toString(16)
    .toUpperCase();
  if (hex.length === 1) {
    return `0${hex}`;
  }
  return hex;
};

/**
 *
 * @param hexColor Must be a valid 6-digit hex color string with the leading "#"
 * @param opacity A number between 0 and 1
 */
export const fade = (hexColor: string, opacity: number) => {
  return `${hexColor}${hexOpacity(opacity)}`;
};

export const useTheme = () => useContext(ThemeContext);
