import React from 'react';
import { Container } from 'components/Container';
import { DocumentEditor } from 'components/Editor';
import { DocumentNavigator } from 'components/DocumentNavigator';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import { AuthModal } from 'views/LoginSignup/AuthModal';
import { AuthHeader } from 'components/AuthHeader';

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
`;

const EditorContainer = styled(Container)`
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

export const EditorView = () => {
  return (
    <Div100vh>
      <ContentContainer>
        <DocumentNavigator />
        <EditorContainer>
          <DocumentEditor />
        </EditorContainer>
      </ContentContainer>
      <AuthHeader />
      <AuthModal />
    </Div100vh>
  );
};
