import { RenderBlockProps, useParentEditor } from 'components/BlockEditor';
import React from 'react';
import styled from 'styled-components';

const CheckListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0;
  padding-inline-start: 15px;
  & + & {
    margin-top: 0;
  }
`;

const CheckboxWrapper = styled.span`
  margin-right: 0.75em;
`;

const LabelWrapper = styled.span<{ checked: boolean }>`
  flex: 1;
  opacity: ${(props) => (props.checked ? 0.666 : 1)};
  text-decoration: ${(props) => (props.checked ? 'line-through' : 'none')};
  &:focus {
    outline: none;
  }
`;

const Checkbox = styled.input`
  height: 1.2rem;
  width: 1.2rem;
`;

export const CheckListItemBlock = ({ block, inputProps }: RenderBlockProps) => {
  const editor = useParentEditor();

  const toggleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    editor.transformBlock(block.id, {
      ...block,
      attributes: {
        ...(block.attributes || {}),
        checked: !block.attributes?.checked,
      },
    });
  };

  return (
    <CheckListItemContainer>
      <CheckboxWrapper contentEditable={false}>
        <Checkbox
          type="checkbox"
          checked={!!block.attributes?.checked}
          onChange={toggleChecked}
        />
      </CheckboxWrapper>
      <LabelWrapper checked={!!block.attributes?.checked} {...inputProps}>
        {block.value}
      </LabelWrapper>
    </CheckListItemContainer>
  );
};
