import React from 'react';
import { Modal } from 'components/Modal';
import { setAuthModalState, useAuthModalState } from 'state/auth';
import { LoginForm } from './LoginForm';
import { SignupForm } from './SignupForm';

export const AuthModal = () => {
  const state = useAuthModalState();
  return (
    <Modal
      open={state.open}
      onClickBackdrop={() => setAuthModalState({ open: false })}
    >
      {state.view === 'signup' ? (
        <SignupForm onClickLogin={() => setAuthModalState({ view: 'login' })} />
      ) : (
        <LoginForm
          onClickSignup={() => setAuthModalState({ view: 'signup' })}
        />
      )}
    </Modal>
  );
};
