import React from 'react';
import { Container } from 'components/Container';
import Div100vh from 'react-div-100vh';
import { MyBlockEditor } from 'components/Editor/MyBlockEditor';

export const ScratchpadScreen = () => {
  const handleSave = () => {};
  return (
    <Div100vh>
      <Container>
        <MyBlockEditor handleSave={handleSave} />
      </Container>
    </Div100vh>
  );
};
