import React from 'react';
import { BlockType, Block, RenderBlockFn } from './types';
import { BlockElement } from './Block';
import { nanoid } from 'nanoid';
import { logger } from 'lib/logger';
import { useBlockState } from './state';

const splitAt = (str: string, index: number): [string, string] => [
  str.slice(0, index),
  str.slice(index),
];

export type BlockSlice = Omit<Block, 'id'>;

const makeBlockId = () => `block-${nanoid()}`;

const prepInitialBlocks = (blocks: BlockSlice[]): Block[] =>
  blocks.map((block) => ({
    ...block,
    id: makeBlockId(),
  }));

interface BlockEditorParams {
  initialBlocks?: BlockSlice[];
  multilineBlocks?: BlockType[];
  hotKeys?: Record<string, BlockType>;
}
/**
 * TODOS:
 * > allow passing in a config of different elements and how to render them
 * > add a way to mutate a block ie, change a paragraph to a title
 * > add a way to bold / italic / underline blocks that support it
 * > add a way to bold / italic / underline parts of a block that supports it
 * > Add a way to indtercept change values for each element in the editor
 *    so we can do stuff like support automatic markdown rendering with blocks
 * > Look to the Slate API for design ideas
 */
export const useBlockEditor = (params?: BlockEditorParams) => {
  // TODO: refactor the state portion of this out into a separate hook
  const state = useBlockState(
    prepInitialBlocks(
      params?.initialBlocks ?? [{ type: 'paragraph', value: '' }]
    )
  );

  const resetEditorState = (newBlocks?: BlockSlice[]) => {
    state.resetState(
      prepInitialBlocks(newBlocks ?? [{ type: 'paragraph', value: '' }])
    );
  };

  const isMultilineBlock = (block: Block) => {
    return !!params?.multilineBlocks?.includes(block.type);
  };

  const handleKeyDown = (e: React.KeyboardEvent, block: Block) => {
    if (e.metaKey || e.ctrlKey) {
      // TODO: Check for hotkeys
      logger.debug('Hotkeys not yet implemented');
    }
    if (e.key === 'Enter') {
      // Standard keydown handlers
      // Create new block below on enter press
      e.preventDefault();

      // Split the value of the current line onto 2 lines if necessary
      const cursorOffset = state.cursor.getAndCacheCursorOffset();
      logger.debug('Cursor offset', cursorOffset);
      const splitLine = splitAt(block.value, cursorOffset);
      block.value = splitLine[0];
      state.insertBlockBelow(
        isMultilineBlock(block) ? block.type : 'paragraph',
        splitLine[1]
      );
      state.cursor.cacheCursorOffset(0);
    }
    // Move up the document
    if (e.key === 'ArrowUp') {
      state.cursor.getAndCacheCursorOffset(); // maintain relative cursor position when moving between blocks
      state.setPrevBlockActive();
    }
    // Move down the document
    if (e.key === 'ArrowDown') {
      state.cursor.getAndCacheCursorOffset(); // maintain relative cursor position when moving between blocks
      state.setNextBlockActive();
    }

    if (e.key === 'Backspace') {
      // 1. If line is empty string, delete line and to go previous block
      // 2. If position is 0 and line is non empty, append contents of active block to previous block and make previous block active block
      const offset = state.cursor.getAndCacheCursorOffset();
      if (offset === 0 && block.value === '') {
        state.removeActiveBlock();
      }
      if (offset === 0 && block.value !== '') {
        const prevBlock = state.activeBlock?.prev?.value;
        if (prevBlock) {
          const prevContentLength = prevBlock.value.length;
          prevBlock.value += block.value;
          state.removeActiveBlock();
          state.cursor.cacheCursorOffset(prevContentLength);
        }
      }
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent, block: Block) => {
    // Markdown-esque shortcuts for different blocks
    // TODO: move this out o the core editor and into the options passed to the editor
    // pass a patern for transform, and then the block type to transform it into
    if (/^#\s{1}/.test(block.value) && block.type === 'paragraph') {
      state.transformActiveBlock({
        type: 'heading-1',
        value: block.value || ' ', // a workaround to keep the cursor visible
      });
    } else if (/^##\s{1}/.test(block.value) && block.type === 'paragraph') {
      state.transformActiveBlock({
        type: 'heading-2',
        value: block.value || ' ',
      });
    } else if (/^###\s{1}/.test(block.value) && block.type === 'paragraph') {
      state.transformActiveBlock({
        type: 'heading-3',
        value: block.value || ' ',
      });
    } else if (/^\*\s{1}/.test(block.value) && block.type === 'paragraph') {
      state.transformActiveBlock({
        type: 'bullet-list-item',
        value: block.value || ' ',
      });
    } else if (/^\[\]\s{1}/.test(block.value) && block.type === 'paragraph') {
      state.transformActiveBlock({
        type: 'check-list-item',
        value: block.value || ' ',
        attributes: {
          checked: false,
        },
      });
    } else if (/^\[x\]\s{1}/.test(block.value) && block.type === 'paragraph') {
      state.transformActiveBlock({
        type: 'check-list-item',
        value: block.value || ' ',
        attributes: {
          checked: true,
        },
      });
    }
    // TODO: Think, can we debounce this?
    state.cursor.getAndCacheCursorOffset();
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const content = e.clipboardData.getData('Text');
    const lines = content.split('\n');
    if (lines.length > 1) {
      e.preventDefault();
      state.insertBlockBelow('paragraph', lines);
    }
  };

  const getValue = (): Block[] =>
    state.blocks.getValues().map((block) => ({
      type: block.type,
      value: block.value,
      id: block.id,
    }));

  // effects

  return {
    handleKeyDown,
    handleKeyUp,
    blocks: state.blocks.toNodeArray(),
    setActiveBlock: state.setActiveBlock,
    activeBlockType: state.activeBlockType,
    activeBlock: state.activeBlock,
    toggleBlockType: state.toggleActiveBlockType,
    getCursorOffset: state.cursor.getCachedCursorOffset,
    transformBlock: state.transformBlock,
    handlePaste,
    getValue,
    resetEditorState,
  };
};

export type BlockEditorManager = ReturnType<typeof useBlockEditor>;

interface BlockEditorProps {
  editor: BlockEditorManager;
  renderBlock: RenderBlockFn;
  onChange?: (value: Block[]) => void;
}
const EditorContext = React.createContext<BlockEditorManager | undefined>(
  undefined
);

export const useParentEditor = () => {
  const editor = React.useContext(EditorContext);
  if (!editor) {
    throw new Error('Tried to useEditor outside of a valid editor context');
  }
  return editor;
};

export const BlockEditor = ({
  editor,
  renderBlock,
  onChange,
}: BlockEditorProps) => {
  const handleChange = React.useCallback(
    (e: React.KeyboardEvent, block: Block) => {
      editor.handleKeyUp(e, block);
      // FIXME: This setTimeout is a hack because otherwise the last key may not have been committed to the state
      // by the time this fires
      setTimeout(() => {
        const value = editor.getValue();
        onChange && onChange(value);
      }, 10);
    },
    [editor, onChange]
  );

  return (
    <EditorContext.Provider value={editor}>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {editor.blocks.map((blockNode, index) => (
          <BlockElement
            tabIndex={index + 1}
            block={blockNode.value}
            active={editor.activeBlock?.id === blockNode.id}
            key={blockNode.id}
            onKeyDown={(e) => editor.handleKeyDown(e, blockNode.value)}
            onFocus={() => editor.setActiveBlock(blockNode)}
            renderBlock={renderBlock}
            onKeyUp={(e) => handleChange(e, blockNode.value)}
            getCursorOffset={editor.getCursorOffset}
            onPaste={editor.handlePaste}
          />
        ))}
      </div>
    </EditorContext.Provider>
  );
};
