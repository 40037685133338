import styled from 'styled-components';

export const IconButton = styled.button`
  display: flex;
  border-radius: 50%;
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 5px;
  &:hover {
    transform: scale(1.1);
  }
  font-size: 1.5rem;
  outline: none;
  transition: all 0.1s;
`;
