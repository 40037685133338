import React from 'react';
import { saveDocument } from 'state/documents';
import { useActiveDocument } from 'state/editor';
import { MyBlockEditor } from './MyBlockEditor';
import { Block } from 'components/BlockEditor';

export const DocumentEditor = (/*props: EditorProps*/) => {
  const doc = useActiveDocument();
  if (!doc) {
    return null;
  }

  const handleSave = (blocks: Block[]) => {
    saveDocument({
      ...doc,
      title: blocks[0]?.value ?? 'Untitled note',
      content: JSON.stringify(blocks),
    });
  };
  return <MyBlockEditor handleSave={handleSave} />;
};
