import React from 'react';
import styled from 'styled-components';

export const EditorButton = styled.button<{ active?: boolean }>`
  padding: ${(props) => props.theme.spacing(1)}
    ${(props) => props.theme.spacing(2)};
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  color: ${(props) =>
    props.active
      ? props.theme.colors.text.dark
      : props.theme.colors.text.light};
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.background.dark
      : props.theme.colors.background.light};
`;

type EditButtonProps = {
  cmd: string;
  arg?: string;
  name?: string;
  active?: boolean;
  children?: any;
};

export const DocumentButton = (props: EditButtonProps) => {
  return (
    <EditorButton
      active={props.active}
      key={props.cmd}
      onMouseDown={(evt) => {
        evt.preventDefault(); // Avoids losing focus from the editable area
        document.execCommand(props.cmd, false, props.arg); // Send the command to the browser
      }}
    >
      {props.children || props.name}
    </EditorButton>
  );
};
