import React from 'react';
import { Input } from 'components/Fields/Input';
import { Box, Paper } from 'components/Box';
import { LinkButton, PrimaryButton } from 'components/Buttons';
import { signUpWithEmailAndPassword } from 'lib/auth';
import { useForm } from 'react-hook-form';
import { logger } from 'lib/logger';

interface Props {
  onClickLogin: () => void;
}

interface SignupData {
  email: string;
  password: string;
  emailConfirmation: string;
}

export const SignupForm = (props: Props) => {
  const { register, handleSubmit, reset } = useForm<SignupData>();
  const onSubmit = async (data: any) => {
    logger.debug('Submitting: ', data);
    if (data.email && data.password && data.email === data.emailConfirmation) {
      const result = await signUpWithEmailAndPassword(
        data.email,
        data.password
      );
      reset();
    }
  };
  return (
    <Paper justify="center" align="center" p={1}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box row>
          <Input
            type="text"
            name="email"
            placeholder="Email address"
            ref={register}
          />
        </Box>
        <Box row>
          <Input
            type="text"
            name="emailConfirmation"
            placeholder="Confirm email address"
            ref={register}
          />
        </Box>
        <Box row>
          <Input
            type="password"
            name="password"
            placeholder="Choose a password"
            ref={register}
          />
        </Box>
        <Box>
          <PrimaryButton>Sign up</PrimaryButton>
          <LinkButton
            onClick={(e) => {
              // Prevent submitting the form
              e.preventDefault();
              props.onClickLogin();
            }}
          >
            Log in instead
          </LinkButton>
        </Box>
      </form>
    </Paper>
  );
};
