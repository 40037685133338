import styled from 'styled-components';

export const Toolbar = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => props.theme.spacing(1)};
  border-radius: ${(props) => props.theme.borderRadius(2)};
  @media (max-width: 600px) {
    overflow-x: scroll;
    z-index: 1000;
    width: 100%;
    margin: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
