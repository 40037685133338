export enum LogLevel {
  Prod = 0,
  Debug = 1000,
  Trace = 1000000,
}

let _logLevel =
  process.env.REACT_APP_LOG_LEVEL === 'debug' ? LogLevel.Trace : LogLevel.Prod;

export const logger = {
  log: (...args: any) => {
    console.log('[LOG]: ', ...args);
  },
  debug: (...args: any) => {
    if (_logLevel >= LogLevel.Debug) {
      console.log('[DEBUG]: ', ...args);
    }
  },
};
