import React from 'react';

/**
 *
 * @returns The cursor offset if there is a selection or -1 if nothing is selected
 * this is highly simplified, look at the answers on https://stackoverflow.com/questions/3972014/get-contenteditable-caret-position for a more full featured solution
 */
export const getSelectionOffset = () => {
  const range = window.getSelection()?.getRangeAt(0);
  return range?.endOffset ?? -1;
};

/**
 * Sets the cursor / caret to the end of a particular contentEditable element.
 * src: https://stackoverflow.com/questions/1125292/how-to-move-cursor-to-end-of-contenteditable-entity
 */
export const setCursorToEndOfLine = (el: HTMLElement) => {
  if (document.createRange) {
    const oldRange = window.getSelection()?.getRangeAt(0).cloneRange();
    const range = document.createRange(); //Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(el); //Select the entire contents of the element with the range
    // Set the end of the new range to be the end of the content of the original range
    if (oldRange?.endContainer) {
      range.setEnd(oldRange?.endContainer, el.innerText.length);
    }
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    const selection = window.getSelection(); //get the selection object (allows you to change selection)
    selection?.removeAllRanges(); //remove any selections already made
    selection?.addRange(range); //make the range you have just created the visible selection
  }
  // const range = window.getSelection()?.getRangeAt(0);
  // range && range.collapse(false);
  // console.log('RANGE: ', range);
};

/**
 * Takes a block ID and manually sets the window selection to a position in that block element
 */
export const setCaretPosition = (block: HTMLElement, xOffset: number) => {
  const range = document.createRange();
  const selection = window.getSelection();
  if (block && selection && block.childNodes[0]) {
    range.setStart(block.childNodes[0], xOffset);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const isHotkey = (e: React.KeyboardEvent, pattern: string) => {
  const keys = pattern.split('+').map((k) => k.toLowerCase());
  return keys.reduce((isFullMatch, key) => {
    return (
      isFullMatch &&
      !!(
        (key === 'ctrl' && e.ctrlKey) ||
        (key === 'cmd' && e.metaKey) ||
        (key === 'alt' && e.altKey) ||
        (key === 'shift' && e.shiftKey) ||
        key === e.key
      )
    );
  }, true);
};

export const useCurrentSelection = () => {
  const selection = React.useRef<string>();
  React.useEffect(() => {
    const handler = (e: any) => {
      // console.log('SELECTION START: ', window.getSelection()?.toString());
      selection.current = window.getSelection()?.toString();
    };
    document.addEventListener('selectionchange', handler);

    return () => document.removeEventListener('selectionchange', handler);
  }, []);

  return selection.current;
};

/**
 * A hook that tracks if the user is dragging their cursor across the document
 * For the purpose of this function, a drag is considered to be the following sequence of events:
 * 1. User left clicks mouse and holds
 * 2. While holding, cursor moves > dragThreshold
 *
 * @param dragThreshold the minimum number of pixels to register a drag event
 * @returns boolean
 */
export const useIsDragging = (dragThreshold: number = 50) => {
  const clickStart = React.useRef<{ x: number; y: number } | undefined>();
  const [dragging, setDragging] = React.useState<boolean>(false);
  React.useEffect(() => {
    const moveHandler = (e: MouseEvent) => {
      if (clickStart.current) {
        if (
          Math.abs(e.x - clickStart.current.x) >= dragThreshold ||
          Math.abs(e.y - clickStart.current.y) >= dragThreshold
        ) {
          setDragging(true);
        }
      }
    };
    const downHandler = (e: MouseEvent) => {
      clickStart.current = {
        x: e.x,
        y: e.y,
      };
    };
    const upHandler = (e: MouseEvent) => {
      clickStart.current = undefined;
      setDragging(false);
    };
    document.addEventListener('mousedown', downHandler);
    document.addEventListener('mouseup', upHandler);
    document.addEventListener('mousemove', moveHandler);

    return () => {
      document.removeEventListener('mousemove', moveHandler);
      document.removeEventListener('mousedown', downHandler);
      document.removeEventListener('mouseup', upHandler);
    };
  }, [dragThreshold]);

  return dragging;
};
