import React, { useCallback, useEffect, useRef } from 'react';
import { BlockElementProps } from './types';
import styled from 'styled-components';
import { setCaretPosition } from './utils';

const BlockWrapper = styled.div`
  min-height: 1em;
  outline: none;
  cursor: text;
  font-size: 20px;
  overflow: auto;
  & > * {
    outline: none;
    display: block;
  }
  transition: all 0.25s ease-in-out;
`;
// TODO: Think about modifying this to use a recursive approach
export const BlockElement = (props: BlockElementProps) => {
  const { block, active, getCursorOffset } = props;
  const elementRef = useRef<HTMLElement>(); // TODO: type this

  const setValue = useCallback(
    (val: string) => {
      block.value = val;
    },
    [block]
  );

  useEffect(() => {
    // focus when we are the active block
    if (active && elementRef?.current) {
      elementRef.current.focus();
      const offset = getCursorOffset();
      // Only update the cursor position if it is a non-zero value
      // This is to prevent sending the cursor to the begnning of the line when a user makes a selection via mouse click
      offset &&
        setCaretPosition(
          elementRef.current,
          Math.min(offset, block.value.length)
        );
    }
  }, [active, getCursorOffset, block.value]);

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLElement>) => {
      // do nothing otherwise
      const _value = elementRef?.current?.innerText ?? '';
      setValue(_value);
      // Emit a new event for anyone who wants it
      props.onChange && props.onChange(_value);
    },
    [props, setValue]
  );

  const inputProps = {
    onInput: handleChange,
    onKeyDown: props.onKeyDown,
    onKeyUp: props.onKeyUp,
    onFocus: props.onFocus,
    ref: elementRef,
    contentEditable: active,
    suppressContentEditableWarning: true,
    spellCheck: true,
    id: block.id,
    tabIndex: props.tabIndex,
    onPaste: props.onPaste,
  };

  return (
    <BlockWrapper onClick={() => inputProps.onFocus?.()}>
      {props.renderBlock({ block, inputProps })}
    </BlockWrapper>
  );
};
