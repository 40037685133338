import React, { useMemo } from 'react';
import { List, ListNode } from 'lib/linked-list';
import { Block, BlockType } from './types';
import { nanoid } from 'nanoid';
import { getSelectionOffset, useIsDragging } from './utils';

const makeBlockId = () => `block-${nanoid()}`;

export interface BlockEditorState {
  blocks: Record<string, Block>;
  blockOrder: string[]; // array of block ids
  activeBlockIndex: number;
  lastKnownCursorOffset: number;
}

const useForceUpdate = () => {
  const [value, setValue] = React.useState(0);

  const forceUpdate = React.useCallback(() => {
    setValue(value + 1);
  }, [value]);

  return forceUpdate;
};

/**
 *
 * Cursor offset state is managed via a ref for performance reasons
 * That is, we may want to update this value very frequently without explicitly
 * triggering react updates
 *
 * NOTE: This currently does not always represent the exact position of the cursor
 * rather, it is used to cache values when we care about knowing the last cursor position
 * eg when we switch active blocks, we would like to maintain relative cursor position
 * and so use this state to cache the value before switching blocks and then setting it on the new block
 */
const useCursorOffset = () => {
  const offsetRef = React.useRef<number>(0);

  const getCachedCursorOffset = React.useCallback(
    () => offsetRef.current,
    [offsetRef]
  );

  const cacheCursorOffset = React.useCallback(
    (offset: number) => {
      offsetRef.current = offset;
    },
    [offsetRef]
  );

  const getAndCacheCursorOffset = React.useCallback(() => {
    cacheCursorOffset(getSelectionOffset());
    return offsetRef.current;
  }, [cacheCursorOffset]);

  const clearCursorCache = React.useCallback(() => {
    offsetRef.current = 0;
  }, []);

  return {
    getCachedCursorOffset,
    cacheCursorOffset,
    getAndCacheCursorOffset,
    clearCursorCache,
  };
};

export const useBlockState = (initialBlocks?: Block[]) => {
  const [blocks, setBlocks] = React.useState(new List(initialBlocks));
  const [activeBlock, setActiveBlock] = React.useState<ListNode<Block>>();
  const triggerUpdate = useForceUpdate();
  const cursor = useCursorOffset();
  const dragging = useIsDragging();

  const activeBlockType = useMemo(
    () => activeBlock?.value?.type,
    [activeBlock]
  );

  const resetState = (newBlocks?: Block[]) => {
    setActiveBlock(undefined);
    cursor.clearCursorCache();
    setBlocks(new List(newBlocks));
  };

  // Inserts a new block below the current active block
  const insertBlockBelow = (type: BlockType, value?: string | string[]) => {
    // TODO: Think of a way around this additional lookup
    // perhaps we could add an insertAfter(id: string) method t o the list
    const [_, activeIndex] = (activeBlock &&
      blocks.findNode(activeBlock.id)) ?? [0, 0];
    const toInsert = Array.isArray(value) ? value : [value];
    let inserted;
    // We insert at the next available index since we want the line to after the current block position
    // not in place of it
    let idx = activeIndex + 1;
    toInsert.forEach((v) => {
      inserted = blocks.insert(idx, {
        type,
        value: v ?? '',
        id: makeBlockId(),
      });
      idx += 1;
    });
    // Set the active block as the last inserted
    setActiveBlock(inserted);
  };

  const removeBlock = (blockId: string) => {
    blocks.remove(blockId);
  };

  const removeActiveBlock = () => {
    if (activeBlock) {
      const newActive = activeBlock.prev;
      activeBlock?.id && blocks.remove(activeBlock?.id);
      setActiveBlock(newActive);
    }
  };

  const transformBlock = (id: string, params: Block) => {
    blocks.updateNode(id, params);
    triggerUpdate();
  };

  const transformActiveBlock = (params: Partial<Omit<Block, 'id'>>) => {
    if (activeBlock) {
      transformBlock(activeBlock.id, {
        ...activeBlock.value,
        ...params,
      });
    }
    // triggerUpdate();
  };

  const toggleActiveBlockType = (newType: BlockType) => {
    transformActiveBlock({
      type: activeBlock?.value.type === newType ? 'paragraph' : newType,
    });
  };

  const setNextBlockActive = () => {
    if (activeBlock?.next) {
      setActiveBlock(activeBlock.next);
    }
  };

  const setPrevBlockActive = () => {
    if (activeBlock?.prev) {
      setActiveBlock(activeBlock.prev);
    }
  };

  return {
    blocks,
    insertBlockBelow,
    removeBlock,
    activeBlockType,
    toggleActiveBlockType,
    transformActiveBlock,
    transformBlock,
    removeActiveBlock,
    // If the user is dragging a selection, better to not
    // set an active block since we aren't sure where the cursor will end up
    activeBlock: dragging ? undefined : activeBlock,
    setActiveBlock,
    cursor,
    setNextBlockActive,
    setPrevBlockActive,
    resetState,
  };
};
