import { Firebase } from './firebase';

export const AuthService = Firebase.auth();

export const signUpWithEmailAndPassword = (email: string, password: string) => {
  return (
    AuthService
      // Stay signed in until we sign out explicitly
      .setPersistence(Firebase.auth.Auth.Persistence.LOCAL)
      .then(() =>
        AuthService.createUserWithEmailAndPassword(email, password).then(
          (credential) => credential.user
        )
      )
  );
};

export const signInWithEmailAndPassword = (email: string, password: string) => {
  return AuthService.signInWithEmailAndPassword(email, password).then(
    (credential) => credential.user
  );
};

export const signOut = () => {
  return AuthService.signOut();
};
