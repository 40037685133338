import React from 'react';
import styled from 'styled-components';

const ModalBackdrop = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  visibility: ${(props) => (props.open ? 'auto' : 'hidden')};
  background-color: rgba(0, 0, 0, 0.3);
`;

const ModalContent = styled.div<{ open: boolean }>`
  position: fixed;
  z-index: 2000;
  top: ${(props) => (props.open ? '40%' : '100%')};
  left: 0;
  right: 0;
  // margin-right: auto;
  // margin-left: auto;
  justify-content: center;
  display: flex;
  visibility: ${(props) => (props.open ? 'auto' : 'hidden')};
  transition: all 0.2s ease-in-out;
`;

type ModalProps = {
  children: any;
  onClickBackdrop: () => void;
  open: boolean;
};
export const Modal = (props: ModalProps) => {
  return (
    <>
      <ModalBackdrop open={props.open} onClick={props.onClickBackdrop} />
      <ModalContent open={props.open}>{props.children}</ModalContent>
    </>
  );
  // <ModalBackdrop open={props.open} onClick={props.onClickBackdrop}>
  //   {props.children}
  // </ModalBackdrop>
};
