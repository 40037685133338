import React from 'react';
import './App.css';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { darkTheme, defaultTheme } from 'lib/theme';
import { EditorView } from 'views/EditorView';
import { useActiveTheme } from 'state/theme';
import { initAuthState } from 'state/auth';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ScratchpadScreen } from 'views/Scratchpad';

const GlobalStyles = createGlobalStyle`
  body, html {
    background-color: ${(props) => props.theme.colors.background.main};
    color: ${(props) => props.theme.colors.text.main};
  }

  svg {
    fill: ${(props) => props.theme.colors.text.main};
  }

  * {
    box-sizing: border-box;
  }
`;

initAuthState();

function App() {
  const activeTheme = useActiveTheme();
  return (
    <ThemeProvider theme={activeTheme === 'dark' ? darkTheme : defaultTheme}>
      <GlobalStyles />
      <BrowserRouter>
        <Switch>
          <Route path="/scratch">
            <ScratchpadScreen />
          </Route>
          <Route path="/">
            <EditorView />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
