import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyC2P7E4w2t7HN-hglstXv71UrDbHpxWRxU',
  authDomain: 'graymatter-d4503.firebaseapp.com',
  projectId: 'graymatter-d4503',
  storageBucket: 'graymatter-d4503.appspot.com',
  messagingSenderId: '308013030662',
  appId: '1:308013030662:web:a6204b1d2cb5adcb2c9287',
};

firebase.initializeApp(firebaseConfig);

export const Firebase = firebase;
export type FirebaseUser = firebase.User;

export const db = firebase.firestore();
