import React from 'react';
import { Input } from 'components/Fields/Input';
import { Box, Paper } from 'components/Box';
import { LinkButton, PrimaryButton } from 'components/Buttons';
import { signInWithEmailAndPassword } from 'lib/auth';
import { useForm } from 'react-hook-form';
import { setAuthModalState } from 'state/auth';
import { logger } from 'lib/logger';

interface LoginData {
  email: string;
  password: string;
}

interface Props {
  onClickSignup: () => void;
}
export const LoginForm = (props: Props) => {
  const { register, handleSubmit, reset } = useForm<LoginData>();
  const [serverError, setServerError] = React.useState<string>('');
  const onSubmit = (data: LoginData) => {
    if (data.email && data.password) {
      return signInWithEmailAndPassword(data.email, data.password)
        .then(() => {
          reset();
          setAuthModalState({ open: false });
        })
        .catch((err) => {
          logger.debug('ERROR: ', err);
          setServerError(err.message);
        });
    }
  };
  return (
    <Paper justify="center" align="center" p={1}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box row m={0.5}>
          <Input
            type="text"
            name="email"
            placeholder="me@example.com"
            ref={register}
          />
        </Box>
        <Box row m={0.5}>
          <Input
            type="password"
            name="password"
            placeholder="********"
            ref={register}
          />
        </Box>
        <Box>
          <PrimaryButton>Log in</PrimaryButton>
          <LinkButton
            onClick={(e) => {
              e.preventDefault();
              props.onClickSignup();
            }}
          >
            Sign up instead
          </LinkButton>
        </Box>
        {serverError && <span>{serverError}</span>}
      </form>
    </Paper>
  );
};
