import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { bindAndSubscribe } from 'utils/rx';
import { generateSlug } from 'lib/slug';
import { logger } from 'lib/logger';
import { IDocument } from 'types/documents';
import { saveDocToDB, fetchDocsForUser, removeDocFromDB } from 'lib/document';
import { currentUser$ } from 'state/auth';
import { withLatestFrom } from 'rxjs/operators';

// This is a workaround for nextjs trying to load this server side
const localStorage = global.localStorage || {};

// const NOTE_KEY = 'LNKKTO_NOTE';

const documentKey = (slug: string) => `@@DOCUMENT@@/${slug}`;

const isDocumentKey = (key: string) => /^@@DOCUMENT@@/.test(key);

export const getItem = <T>(key: string): T | null => {
  const item = localStorage.getItem(key);
  if (item !== null) {
    return JSON.parse(item);
  }
  return null;
};

export const putItem = <T>(key: string, item: T) => {
  localStorage.setItem(key, JSON.stringify(item));
};

const persistDocument = (doc: IDocument) => {
  putItem(documentKey(doc.slug), doc);
};

const removeDocument = (slug: string) => {
  localStorage.removeItem(documentKey(slug));
};

const loadDocuments = () => {
  if (localStorage) {
    const keys = Object.keys(localStorage);
    const documents: DocumentStore = {};
    keys.forEach((key) => {
      if (isDocumentKey(key)) {
        const doc = getItem<IDocument>(key);
        if (doc?.slug) {
          documents[doc.slug] = doc;
        }
      }
    });
    return documents;
  }
  return {};
};

// State
//==========

type DocumentStore = {
  [slug: string]: IDocument;
};

export const documentStore$ = new BehaviorSubject<DocumentStore>(
  loadDocuments()
);

export const [useDocuments] = bindAndSubscribe(documentStore$);

export const useDocument = (slug: string) => {
  const docs = useDocuments();
  return docs ? docs[slug] : null;
};

const newDocument$ = new Subject<string>();
export const newDocument = (title: string) => newDocument$.next(title);

export const documentCreated$ = new Subject<IDocument>();

const saveDocument$ = new Subject<IDocument>();
export const saveDocument = (doc: IDocument) => {
  const _doc = {
    ...doc,
    updatedAt: Date.now(),
  };
  saveDocument$.next(_doc);
};

const deleteDocument$ = new Subject<string>();
export const deleteDocument = (slug: string) => deleteDocument$.next(slug);

// Handle creating a new doc
newDocument$.subscribe((title) => {
  const now = Date.now();
  const doc: IDocument = {
    slug: generateSlug(),
    content: JSON.stringify([
      // {
      //   type: 'heading-one',
      //   children: [
      //     {
      //       text: title,
      //     },
      //   ],
      // },
      {
        type: 'heading-1',
        value: title,
      },
    ]),
    createdAt: now,
    updatedAt: now,
    title,
  };
  logger.debug('Created document: ', doc);
  saveDocument$.next(doc);
  documentCreated$.next(doc);
});

saveDocument$.subscribe((doc) => {
  persistDocument(doc);
  // Update the document store
  documentStore$.next({
    ...documentStore$.value,
    [doc.slug]: doc,
  });
  logger.debug('Saved document: ', doc);
});

saveDocument$
  .pipe(withLatestFrom(currentUser$))
  .subscribe(([doc, currentUser]) => {
    if (currentUser) {
      // Only persist the document to cloud if we are currently logged in
      saveDocToDB(doc, currentUser);
    } else {
      logger.debug('Skipping cloud backup as no logged in user found');
    }
  });

deleteDocument$.subscribe((slug) => {
  removeDocument(slug);
  removeDocFromDB(slug);
  const copy = {
    ...documentStore$.value,
  };
  delete copy[slug];

  documentStore$.next(copy);
  logger.debug('Deleted docuement: ', slug);
});

const syncRemoteDocuments = (remoteDocs: IDocument[]) => {
  const remoteDocMap: DocumentStore = remoteDocs.reduce((docMap, doc) => {
    return {
      ...docMap,
      [doc.slug]: doc,
    };
  }, {});

  logger.debug('SYNCING DOCUMENTS TO LOCAL STORE: ', remoteDocMap);

  // IMPORTANT: For now, we just overwrite all local changes to documents if there is a remote counterpart
  // TODO: Come up with a better way for merging documents that exist both on the server and client
  documentStore$.next({
    ...documentStore$.value,
    ...remoteDocMap,
  });
};

const mapRemoteToLocalDoc = (remoteDoc: any): IDocument => ({
  createdAt: remoteDoc.createdAt,
  updatedAt: remoteDoc.updatedAt,
  content: remoteDoc.content,
  title: remoteDoc.title,
  slug: remoteDoc.slug,
});

// When a user is set, perform the following operations:
// 1. Fetch all documents for that user in the cloud
// 2. Get all documents in local storage
// 3. Sync 1 and 2
currentUser$
  .pipe(distinctUntilChanged((prev, current) => prev?.uid === current?.uid))
  .subscribe((user) => {
    if (user) {
      fetchDocsForUser(user).then((snapshot) => {
        const remoteDocs: IDocument[] = [];
        snapshot.forEach((doc) => {
          remoteDocs.push(mapRemoteToLocalDoc(doc.data()));
        });
        syncRemoteDocuments(remoteDocs);
      });
    }
  });
