import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  width: 80%;
  max-width: 1440px;

  @media (max-width: 600px) {
    width: 90%;
  }
`;
