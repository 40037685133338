import React from 'react';
import styled from 'styled-components';
import { useCurrentUser, setAuthModalState } from 'state/auth';
import { signOut } from 'lib/auth';
import { LinkButton } from 'components/Buttons';

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
`;

export const AuthHeader = () => {
  const currentUser = useCurrentUser();

  const handleSignOut = () => {
    const confirmed = window.confirm('Are you sure you want to log out?');
    if (confirmed) {
      signOut();
    }
  };

  return (
    <Container>
      {currentUser ? (
        <LinkButton onClick={handleSignOut}>{currentUser.email}</LinkButton>
      ) : (
        <LinkButton
          onClick={() => setAuthModalState({ open: true, view: 'login' })}
        >
          Login
        </LinkButton>
      )}
    </Container>
  );
};
