import { nanoid } from 'nanoid';

export class ListNode<T> {
  id: string;
  prev: ListNode<T> | undefined;
  next: ListNode<T> | undefined;
  private _value: T;

  constructor(value: T, id?: string) {
    this.id = id || `node-${nanoid()}`;
    this._value = value;
    this.prev = undefined;
    this.next = undefined;
  }

  get value() {
    return this._value;
  }

  set value(v: T) {
    this._value = v;
  }
}

// inspired by: https://betterprogramming.pub/singly-and-doubly-linked-lists-in-javascript-7515f47c9f60
export class List<T> {
  head: ListNode<T> | undefined;
  tail: ListNode<T> | undefined;
  length: number;

  constructor(items?: T[]) {
    this.head = undefined;
    this.tail = undefined;
    this.length = 0;
    if (items) {
      items.forEach((item) => {
        this.push(item);
      });
    }
  }

  /**
   *
   * Adds new node onto the end of the list
   */
  public push(val: T) {
    const node = new ListNode(val, (val as any).id);
    if (this.head && this.tail) {
      this.tail.next = node;
      node.prev = this.tail;
      this.tail = node;
    } else {
      // This is the first node in the list
      this.head = node;
      this.tail = node;
    }
    this.length += 1;
    return node;
  }

  /**
   *
   * Removes and returns the current end of the list
   */
  public pop() {
    if (this.length === 0) return;

    const popped = this.tail;
    const newTail = this.tail?.prev;
    if (newTail && this.tail) {
      newTail.next = undefined;
      this.tail.prev = undefined;
    } else {
      // make sure to set the head null if we just popped the only node
      this.head = undefined;
    }
    this.tail = newTail;
    this.length -= 1;
    return popped;
  }

  // remove and return the head of the list
  public shift() {
    if (!this.head) return;

    const shiftedNode = this.head;
    const newHead = this.head.next;

    // list length > 1
    if (this.head !== this.tail) {
      newHead!.prev = undefined;
      shiftedNode.next = undefined;
    } else {
      this.tail = undefined;
    }
    this.head = newHead;
    this.length -= 1;
    return shiftedNode;
  }

  // insert a new value at the head of the list
  public unshift(val: T) {
    const newNode = new ListNode(val);
    if (!this.head) {
      // Handle case where this is the first element in the list
      this.head = newNode;
      this.tail = newNode;
    } else {
      this.head.prev = newNode;
      newNode.next = this.head;
      this.head = newNode;
    }
    this.length += 1;
    return newNode;
  }

  // public insertAfter(nodeId: string, value: T) {}

  // public insertBefore(nodeId: string, value: T) {}

  // Walks the linked list to return the element at the specified index or null
  public getNodeAtIndex(index: number) {
    if (index >= this.length || index < 0) return;

    let currentIdx = 0;
    let currentNode = this.head;

    while (currentIdx !== index) {
      currentNode = currentNode?.next;
      currentIdx++;
    }
    return currentNode;
  }

  // Walks the link list and returns the found element + it's index if it exists in the list
  public findNode(id: string): [ListNode<T> | undefined, number] {
    let currentIdx = 0;
    let currentNode = this.head;
    while (currentNode && currentNode.id !== id) {
      currentNode = currentNode.next;
      currentIdx++;
    }
    return [currentNode, currentIdx];
  }

  public insert(index: number, value: T) {
    if (index > this.length) return;

    if (index === 0) {
      return this.unshift(value);
    } else if (index === this.length) {
      return this.push(value);
    } else {
      const newNode = new ListNode(value);
      // the next value of our new node will be the value currently at that index
      const currentNodeAtIndex = this.getNodeAtIndex(index);
      if (currentNodeAtIndex) {
        const newBefore = currentNodeAtIndex?.prev;
        currentNodeAtIndex.prev = newNode;
        // this can't be null or else index would be 0 which is handled above
        newBefore!.next = newNode;
        newNode.next = currentNodeAtIndex;
        newNode.prev = newBefore;
        this.length += 1;
        return newNode;
      }
    }
  }

  // public insertAfter(nodeId: string, value: T) {
  //   const [node] = this.findNode(nodeId);

  //   if (!node) return;

  //   if (node === this.tail) {
  //     return this.push(value);
  //   }

  //   // We want to insert a node into the middle

  // }

  public updateNode(id: string, value: T) {
    const [node] = this.findNode(id);
    if (node) {
      node.value = value;
    }
    return node;
  }

  public remove(id: string) {
    const [removedNode] = this.findNode(id);
    if (removedNode === this.head) {
      this.shift();
    } else if (removedNode === this.tail) {
      this.pop();
    } else if (removedNode) {
      const after = removedNode.next;
      const before = removedNode.prev;
      removedNode.next = undefined;
      removedNode.prev = undefined;
      // Link the before and after nodes around the node to be removed if they exist
      if (before) {
        before.next = after;
      }
      if (after) {
        after.prev = before;
      }
      this.length -= 1;
    }
    return removedNode;
  }

  // Returns an array of values in the current order
  public getValues() {
    let currentNode = this.head;
    const arr = [];
    while (currentNode) {
      arr.push(currentNode.value);
      currentNode = currentNode.next;
    }
    return arr;
  }

  public toNodeArray() {
    let currentNode = this.head;
    const arr = [];
    while (currentNode) {
      arr.push(currentNode);
      currentNode = currentNode.next;
    }
    return arr;
  }
}
