import styled from 'styled-components';
import { fade } from 'lib/theme';

export const PrimaryButton = styled.button`
  border: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  font-weight: 700;
  padding: 1rem 2rem;
  border-radius: 50px;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.colors.text.light};
`;

export const LinkButton = styled.button`
  border: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  font-weight: 700;
  padding: 1rem 2rem;
  color: ${(props) => props.theme.palette.primary.main};
  background-color: transparent;
  transition: 0.15s all ease-in-out;
  border-radius: 50px;
  &:hover,
  :focus {
    outline: none;
    background-color: ${(props) =>
      fade(props.theme.palette.primary.light, 0.1)};
    // color: ${(props) => props.theme.colors.text.light};
  }
`;
