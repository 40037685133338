import React from 'react';
import { BlockEditorManager, BlockType } from '../BlockEditor';
import { EditorButton } from './EditorButton';

type BlockButtonProps = {
  editor: BlockEditorManager;
  blockType: BlockType;
  icon: any;
};

export const BlockButton = ({ blockType, icon, editor }: BlockButtonProps) => {
  return (
    <EditorButton
      active={editor.activeBlockType === blockType}
      onMouseDown={(event) => {
        event.preventDefault();
        editor.toggleBlockType(blockType);
      }}
    >
      {icon}
    </EditorButton>
  );
};
