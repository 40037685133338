import { Observable } from 'rxjs';
import { bind } from '@react-rxjs/core';

export function bindAndSubscribe<T>(source: Observable<T>) {
  const [hook, shared] = bind(source);
  const subscribedFn = () => undefined;
  shared.subscribe(subscribedFn);

  return [hook, shared] as [() => T, Observable<T>];
}
